import React, { useContext } from 'react';
import { Text as DefaultText } from 'react-native';
import { Colors } from '../constants/Colors';
import Layout from '../constants/Layout'
import ThemeContext from '../contexts/ThemeContext';

type Props = {
    color?: string
};

export type TextProps = Props & DefaultText['props'];

export default function Text(props: TextProps) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];
    const { style, ...otherProps } = props;

    //useContext(ThemeContext);

    const color = props.color !== undefined ? props.color : theme.text;

    return (
        <DefaultText
            style={[
                Layout.defaultComponentsStyles,
                {
                    color: color,
                },
                style]}
            {...otherProps}
        />
    )
}
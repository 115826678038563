import React, { useEffect, useState } from 'react';
import { View, Text, Dimensions, StyleSheet } from 'react-native';
import NetInfo, { NetInfoState } from '@react-native-community/netinfo'
import { Colors } from '../constants/Colors';

const { width } = Dimensions.get('window');

export default function OfflineNotice(prosp: { text: string }) {
  const [offline, setOffline] = useState(false);

  useEffect(() => {
    NetInfo.addEventListener((state: NetInfoState) => setOffline(!state.isConnected ?? true));
  }, []);

  if (offline) return (
    <View style={styles.offlineContainer}>
      <Text style={styles.offlineText}>{prosp.text}</Text>
    </View>
  );
  return null;
}

const styles = StyleSheet.create({
  offlineContainer: {
    backgroundColor: Colors.error,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width,
  },
  offlineText: { color: '#fff' }
});

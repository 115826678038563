import React from "react";
import ModalBottom from "./ModalBottom";
import ModalCloseButton from "./ModalCloseButton";
import View from "./View";


export default function ContentModal(props: { isVisible: boolean, close: any, content: any, style?: any }) {
  return (
    <ModalBottom
      isVisible={props.isVisible}
      close={props.close}
      backdropOpacity={0.4}
      style={[{
        padding: 0, boarderRadisTop: 8,
        width: '96%',
        marginLeft: '2%'
      }, props.style]}
      propagateSwipe
      KeyboardAvoiding={false}
      swipeDirection={[]}
      content={
        <View style={{ height: '100%' }}>
          <View style={{ padding: 20, paddingBottom: 0 }}>
            <ModalCloseButton onPress={props.close} />
          </View>

          <View style={{
            flex: 1,
            height: '100%',
            padding: 0,
            marginTop: 0,
            paddingBottom: 0,
            marginBottom: 0
          }}>
            {props.content()}
          </View>
        </View>
      }
    />
  )
}

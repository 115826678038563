import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, Platform, View } from 'react-native';
import Moment from 'moment';
import ThemeContext from "../contexts/ThemeContext";
import { Colors } from "../constants/Colors";
import Layout from "../constants/Layout";
import Text from "./Text";
import Icon from "./Icon";
import DateTimePicker from "./DateTimePicker";

// TODO add to starter for demostration
export default function InputDateTime(props: {
  onConfirm: (date: any) => void,
  onDelete: () => void,
  mode: "time" | "date" | "datetime",
  text?: string,
  value?: any,
  style?: any
}) {
  const colorScheme = React.useContext(ThemeContext);
  const theme = Colors[colorScheme];

  const [isTimePickerVisible, setTimePickerVisible] = useState(false);
  const [date, setDate] = useState<string>('');

  useEffect(() => {
    if (props.value && !isNaN(props.value)) {
      handleTimeConfirm(props.value);
    } else {
      setDate('');
    }
  }, [props.value]);

  function handleTimeConfirm(selectedDate: any) {
    let formatString = 'DD/MM/yyyy'; // date
    if (props.mode === 'time')
      formatString = 'HH:mm';

    const dateString = Moment(selectedDate).format(formatString);

    setDate(dateString)
    setTimePickerVisible(false);
  }

  return (
    <View>
      <TouchableOpacity onPress={() => setTimePickerVisible(true)}
        style={[{
          backgroundColor: theme.inputBackground, borderColor: theme.inputBorder
        }, styles.button, props.style, Layout.defaultComponentsStyles]}>
        {!date ?
          <Text style={{ color: props.text ? theme.text : theme.textPlaceholder, margin: 0 }}>
            {props.text ?? ''}
          </Text>
          :
          <Text style={{ color: theme.text, margin: 0 }}>
            {props?.text} {date}
          </Text>
        }

        {date ?
          <TouchableOpacity
            onPress={() => {
              setDate('');
              props.onDelete?.();
            }}
            style={{ padding: 5, marginLeft: 0, margin: -10 }}
          >
            <Icon
              style={{ color: theme.text }}
              name={'x'}
              size={25}
              color={theme.text}
            />
          </TouchableOpacity>
          :
          <Icon
            style={{ marginLeft: 8, color: theme.text, marginVertical: Platform.OS === 'ios' ? -5 : 0 }}
            name={props.mode === 'time' ? 'clock' : 'calendar'}
            size={22}
            color={theme.text}
          />
        }
      </TouchableOpacity>

      <DateTimePicker
        mode={props.mode}
        style={{}}
        isVisible={isTimePickerVisible}
        onCancel={() => setTimePickerVisible(false)}
        onConfirm={(newDate: any) => {
          handleTimeConfirm(newDate);
          props.onConfirm(newDate);
        }}
        close={() => setTimePickerVisible(false)}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    padding: 12,
    borderWidth: 1,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
});

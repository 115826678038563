import React from 'react';
import { SectionList as DefaultSectionList } from 'react-native';

export interface SectionListRenderItemInfo<ItemT> {
    item: ItemT;

    index: number;

    separators: {
        highlight: () => void;
        unhighlight: () => void;
        updateProps: (select: 'leading' | 'trailing', newProps: any) => void;
    };
}

export type SectionListRenderItem<ItemT> = (info: SectionListRenderItemInfo<ItemT>) => React.ReactElement | null;

type Props<ItemT> = {
    data?: (any[] & readonly any[]) | undefined,
    renderItem: SectionListRenderItem<ItemT> | null | undefined,
    keyExtractor?: (item: ItemT, index: number) => string
}

export type SectionListProps<ItemT> = Props<ItemT> & DefaultSectionList['props'];

export default function SectionList<ItemT>(props: SectionListProps<ItemT>) {
    const { ...otherProps } = props;

    return (
        <DefaultSectionList
            {...otherProps}>
        </DefaultSectionList>
    )
}
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Layout from '../constants/Layout'
import Text from './Text'
import { CheckBox as CheckBoxWeb } from 'react-native';
// import CheckBoxWeb from '@react-native-community/checkbox';

type Props = {
    text?: string
};

export type CheckBoxProps = Props & CheckBoxWeb['props'];

export default function Checkbox(props: CheckBoxProps) {
    const { style, ...otherProps } = props;

    return (
        <View style={styles.checkboxContainer}>
            <CheckBoxWeb
                style={
                    [
                        Layout.defaultComponentsStyles,
                        {
                            alignSelf: "center",
                        },
                        style]}

                {...otherProps}
            />
            <Text style={styles.label}>{props.text}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 20,
    },
    checkbox: {
        alignSelf: "center",
    },
    label: {
        margin: 8,
    },
});
import React, { useContext, createElement } from 'react';
import { Text as DefaultText, View } from 'react-native';
import { Colors } from '../constants/Colors';
import ThemeContext from '../contexts/ThemeContext';
import { Modal as DefaultModal } from 'react-native-web';
import Button from './Button';

type Props = {
  mode: 'date' | 'time' | 'datetime',
  onConfirm: any,
  onCancel: any
  isVisible?: boolean,
  close?: any
};

// TODO! not working, has to be completed

export type DateTimePickerProps = Props & DefaultText['props'];

export default function DateTimePicker(props: DateTimePickerProps) {
  const colorScheme = useContext(ThemeContext);
  const theme = Colors[colorScheme];
  const { style, ...otherProps } = props;

  let value = new Date();

  const WebDateTimePicker = () => {
    return createElement('input', {
      type: props.mode,
      value: value,
      onInput: (v: any) => value = v,   // TODO check this
    })
  }


  return (
    <DefaultModal
      transparent={true}
      animationType="fade"
      visible={props.isVisible}
      {...otherProps}>
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.8)'
        }}>
        <View
          style={{
            padding: 30,
            borderWidth: 1,
            borderRadius: 10,
            borderColor: theme.text,
            backgroundColor: theme.background
          }}>
          {/* <Text>Hello!</Text> */}

          <WebDateTimePicker></WebDateTimePicker>

          <Button
            title="Hide modal"
            onPress={() => {
              props.onConfirm(value);
              props?.close();
            }} />
        </View>
      </View >
    </DefaultModal >
  )
}

import React from 'react';
import { StyleSheet } from 'react-native';
import Text, { TextProps } from './Text'
import Layout from '../constants/Layout'

type Props = {
};

export type HeaderProps = Props & TextProps;

export default function Header(props: HeaderProps) {
    const { style, ...otherProps } = props;

    return (
        <Text
            style={[Layout.defaultComponentsStyles, styles.text, style]}
            {...otherProps}
        />
    )
}

const styles = StyleSheet.create({
    text: {
        fontSize: 20,
        marginBottom: 10,
        marginTop: 10
    }
});
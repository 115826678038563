import React, { useContext } from 'react';
import { View } from 'react-native';
import { StyleSheet } from 'react-native';
import { Colors } from '../constants/Colors';
import ThemeContext from '../contexts/ThemeContext';
import CardBasic, { CardBasicProps } from './CardBasic'
import Text from './Text'

type Props = {
    title?: String;
    description?: String | undefined
    description2?: String | undefined
};

export type CardProps = Props & CardBasicProps;

export default function Card(props: CardProps) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];
    const { style, ...otherProps } = props;

    return (
        <CardBasic
            style={[
                style]}
            {...otherProps}>
            { props.title ?
                <Text
                    style={styles.title}>
                    {props.title}
                </Text> : <View />
            }
            { props.description ?
                <Text
                    style={[
                        styles.description,
                        { color: theme.textDetail }]
                    }>
                    {props.description}</Text> : <View />
            }
            { props.description2 ?
                <Text
                    style={[
                        styles.description,
                        { color: theme.textDetail, marginTop: 2 }]
                    }>
                    {props.description2}</Text> : <View />
            }
        </CardBasic>
    )
}

const styles = StyleSheet.create({
    title: {
        fontWeight: '700'
    },
    description: {
        fontWeight: '400'
    }
});
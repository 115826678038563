import React, { useContext } from 'react';
import { StyleSheet, View as DefaultView } from 'react-native';
import { Colors } from '../constants/Colors';
import Layout from '../constants/Layout'
import ThemeContext from '../contexts/ThemeContext';

type Props = {
};

export type CardBasicProps = Props & DefaultView['props'];

export default function CardBasic(props: CardBasicProps) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];
    const { style, ...otherProps } = props;

    return (
        <DefaultView
            style={[
                Layout.defaultComponentsStyles,
                styles.input,
                {
                    backgroundColor: theme.card,
                    borderColor: theme.inputBorder,
                },
                style]}
            {...otherProps}
        />

    )
}

const styles = StyleSheet.create({
    input: {
        borderWidth: 1,
        borderRadius: 10,
        padding: 12,
        shadowColor: '#999999',
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.20
    }
});
import React, { useContext } from 'react';
import { StyleSheet, TextInput as DefaultTextInput } from 'react-native';
import { Colors } from '../constants/Colors';
import Layout from '../constants/Layout'
import ThemeContext from '../contexts/ThemeContext';

type Props = {
};

export type TextInputProps = Props & DefaultTextInput['props'];

export default function Input(props: TextInputProps) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];
    const { style, ...otherProps } = props;

    return (
        <DefaultTextInput
            style={[
                styles.input,
                Layout.defaultComponentsStyles,

                {
                    color: theme.text,
                    backgroundColor: theme.inputBackground,
                    borderColor: theme.inputBorder
                },
                style]}
            placeholderTextColor={theme.textPlaceholder}
            {...otherProps}
        />
    )
}

const styles = StyleSheet.create({
    input: {
        borderWidth: 1,
        borderRadius: 8,
        padding: 12
    }
});
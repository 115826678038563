import React, { useEffect, useState } from "react";
import { StyleSheet, Image, Platform } from "react-native";

import AsyncStorage from "@react-native-community/async-storage"
import { Colors, Button, Input, LicenseBox, LoadingIndicator, Text, View, Layout } from '@infominds/react-native-components';
import { useColorScheme } from '@infominds/react-native-components';
import getSystemLanguage, { getLanguageJSON } from "./utils/LanguageUtils";
import LicenseUtil from './LicenseUtil'
import PasswordInput from './components/PasswordInput'

import QRScannerPopUp from "./QRScanner/QRScannerPopUp";
import LicenseGlobals from "./LicenseGlobals";
import ApiLic from "./ApiLic";

Login.defaultProps = {
  getMandants: true,
  getUserInfo: true
}

function Login(props: {
  style: any,
  onLoginClick: () => void,
  customLoginFunction?: (username: string, password: string) => Promise<any>,
  isOffline: boolean,
  demoData?: { lic: string, username: string, password: string },
  projectCode?: string,
  modulCode?: string,
  iconSource?: any,
  iconStyle?: any,
  getMandants?: boolean,
  getUserInfo?: boolean
}) {
  const [isLoading, setLoading] = useState(true);

  const [license, setLicense] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [url, setUrl] = useState('');

  const [licenseError, setLicenseError] = useState(false);
  const [licenseWarn, setLicenseWarn] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [urlError, setUrlError] = useState(false);


  const [notificationText, setNotificationText] = useState('');
  const [notificationType, setNotificationType] = useState('');

  const colorScheme = useColorScheme();
  const theme = Colors[colorScheme]

  // const lang = getLanguageJSON(useLanguage());
  // const lang = useContext(LanguageContext);

  const getLang = async () => { return getLanguageJSON(await getSystemLanguage()) }

  const [language, setLanguage] = useState(getLanguageJSON('en'))
  getLang().then(setLanguage)

  const [isScannerVisible, setScannerVisible] = useState(false);
  const toggleScanner = () => setScannerVisible(!isScannerVisible);

  useEffect(() => {
    if (props.isOffline) {
      AsyncStorage.getItem('url').then(stored => {
        if (stored) setUrl(stored);
      });

      AsyncStorage.getItem('erp').then(erp => {
        if (erp) LicenseGlobals.erp = erp;
      });

      AsyncStorage.getItem('code').then(code => {
        if (code) LicenseGlobals.code = code;
      });
    }
  }, [props.isOffline]);

  useEffect(() => {
    resetErrors();
    AsyncStorage.multiGet(['licenseKey', 'username', 'password']).then((val: any) => {
      if (val.length !== 3) {
        setLoading(false);
        return;
      }
      let lic = val[0][1], user = val[1][1], pass = val[2][1];
      if (lic && user) {
        setUsername(user);
        setPassword(pass);
        setLicense(lic);

        if (props.isOffline) {
          url ? (LicenseGlobals.baseUrl = url.toLocaleLowerCase()) : setUrlError(true);
          LicenseUtil.login(user, pass, props.customLoginFunction)
            .then(() => {
              if (props.getUserInfo)
                ApiLic.getUserInfo().then(async (result: any) => {
                  let storageResult = await AsyncStorage.getItem('mandantId')
                  let savedmandant = -1;

                  LicenseGlobals.employeeID = result.id;

                  if (!storageResult)
                    savedmandant = result.code;
                  else
                    savedmandant = Number(storageResult);

                  // console.debug('Savedmandant', savedmandant);
                  LicenseGlobals.mandantId = savedmandant;
                  AsyncStorage.setItem('mandantId', String(savedmandant));

                  props.onLoginClick()
                  setLoading(false);
                }).catch((error: any) => {
                  setLoading(false);
                  handleLoginError(error);
                  console.error(error)
                });
              else {
                props.onLoginClick()
                setLoading(false);
              }
            }).catch(err => handleLoginError(err));
          return;
        }

        LicenseUtil.registerLicense(lic, user, pass, props.projectCode, props.modulCode, props.customLoginFunction).then(res => {
          console.debug(res);
          if (props.getMandants)
            ApiLic.getMandants().then((man: any) => {
              AsyncStorage.setItem('mandants', JSON.stringify(man))
            }).catch((error: any) => {
              setLoading(false);
              console.error(error)
            });

          if (props.getUserInfo)
            ApiLic.getUserInfo().then(async (result: any) => {

              let storageResult = await AsyncStorage.getItem('mandantId')
              let savedmandant = -1;

              LicenseGlobals.employeeID = result.id;

              if (!storageResult)
                savedmandant = result.code;
              else
                savedmandant = Number(storageResult);

              // console.debug('Savedmandant', savedmandant);
              LicenseGlobals.mandantId = savedmandant;
              AsyncStorage.setItem('mandantId', String(savedmandant));

              props.onLoginClick()
              setLoading(false);
            }).catch((error: any) => {
              setLoading(false);
              console.error(error)
            });
          else {
            props.onLoginClick()
            setLoading(false);
          }
        }
        ).catch((error) => {
          handleLoginError(error);
        });
      } else {
        setLoading(false)
      }

    }).catch(() => { setLoading(false) });
  }, [])

  let resetErrors = () => {
    setLicenseError(false);
    setLicenseWarn(false);
    setUsernameError(false);
    setUrlError(false);
  }

  let handleLoginError = async (error: any) => {
    setLoading(false);
    const lang = await getLang()
    console.debug('HandleLoginError:', error);

    if (error === 'invalid_username_or_password') {
      showNotification(lang.ERROR_WRONG_USERNAME_OR_PASSWORD, 'error');
    } else if (error === 'inactive') {
      error = lang.INFO_DEVICE_NOT_ACTIVATED
      setLicenseWarn(true);
      showNotification(error.toString(), 'warn');
    } else if (error.toString() === 'TypeError: Network request failed') {
      showNotification(lang.ERROR_SERVER_NOT_REACHABLE, 'error');
    } else {
      showNotification(error.toString(), 'error');
    }
  }

  let onLoginButtonPress = () => {
    resetErrors();
    setLoading(true);

    license ? AsyncStorage.setItem('licenseKey', license) : setLicenseError(true);
    if (license) LicenseGlobals.license = license;
    username ? AsyncStorage.setItem('username', username) : setUsernameError(true);
    if (username) LicenseGlobals.username = username;
    AsyncStorage.setItem('password', password);

    if (license && username) {
      if (props.isOffline) {
        url ? (LicenseGlobals.baseUrl = url.toLocaleLowerCase()) : setUrlError(true);
        LicenseUtil.login(username, password, props.customLoginFunction)
          .then(() => {
            if (props.getUserInfo)
              ApiLic.getUserInfo()
                .then(async (result: any) => {
                  let storageResult = await AsyncStorage.getItem('mandantId')
                  let savedmandant = -1;

                  LicenseGlobals.employeeID = result.id;

                  if (!storageResult)
                    savedmandant = result.code;
                  else
                    savedmandant = Number(storageResult);

                  // console.debug('Savedmandant', savedmandant);
                  LicenseGlobals.mandantId = savedmandant;
                  AsyncStorage.setItem('mandantId', String(savedmandant));

                  props.onLoginClick()
                  setLoading(false);
                }).catch((error: any) => {
                  setLoading(false);
                  handleLoginError(error);
                  console.error(error)
                });
            else {
              props.onLoginClick()
              setLoading(false);
            }
          }).catch(err => handleLoginError(err));
        return;
      }

      LicenseUtil.registerLicense(license, username, password, props.projectCode, props.modulCode, props.customLoginFunction)
        .then(() => {
          if (props.getUserInfo)
            ApiLic.getUserInfo().then(async (result: any) => {
              let storageResult = await AsyncStorage.getItem('mandantId')
              let savedmandant = -1;

              LicenseGlobals.employeeID = result.id;

              if (!storageResult)
                savedmandant = result.code;
              else
                savedmandant = Number(storageResult);

              // console.debug('Savedmandant', savedmandant);
              LicenseGlobals.mandantId = savedmandant;
              AsyncStorage.setItem('mandantId', String(savedmandant));

              props.onLoginClick()
              setLoading(false);
            }).catch((error: any) => {
              setLoading(false);
              console.error(error)
            });
          else {
            props.onLoginClick()
            setLoading(false);
          }
        })
        .catch((error) => {
          handleLoginError(error);
        })
    } else {
      setLoading(false);
    }
  }

  let onDemoLogin = () => {
    // console.debug('demo login: ' + props.demoData);
    setLoading(true);

    if (!props.demoData)
      console.error('no demo data for login')
    else
      LicenseUtil.registerLicense(props.demoData?.lic, props.demoData?.username, props.demoData?.password, props.projectCode, props.modulCode, props.customLoginFunction)
        .then(res => {
          console.debug('LoginViewDemo: ', res);

          //props.demoData?.lic ? AsyncStorage.setItem('licenseKey', props.demoData?.lic) : setLicenseError(true);
          if (props.demoData?.lic) LicenseGlobals.license = props.demoData?.lic;

          // console.debug('Demo user: ', props.demoData?.username)
          props.demoData?.username ? AsyncStorage.setItem('username', props.demoData?.username) : setUsernameError(true);
          if (props.demoData?.username) LicenseGlobals.username = props.demoData?.username;

          if (props.demoData) {
            AsyncStorage.setItem('password', props.demoData.password);

            if (props.getUserInfo)
              ApiLic.getUserInfo().then(async (result: any) => {
                let storageResult = await AsyncStorage.getItem('mandantId')
                let savedmandant = -1;

                LicenseGlobals.employeeID = result.id;

                if (!storageResult)
                  savedmandant = result.code;
                else
                  savedmandant = Number(storageResult);

                // console.debug('Savedmandant', savedmandant);
                LicenseGlobals.mandantId = savedmandant;
                AsyncStorage.setItem('mandantId', String(savedmandant));

                props.onLoginClick()
                setLoading(false);
              }).catch((error: any) => {
                setLoading(false);
                console.error(error)
              });
          }

          props.onLoginClick()
        }).catch((error) => {
          handleLoginError(error);
        })
  }

  const showNotification = (text: string, type: 'info' | 'error' | 'warn') => {
    setNotificationText(text);
    setNotificationType(type);
  }

  const ScreenIcon = () => {
    let source = props.iconSource;
    if (!source) {
      if (colorScheme == 'dark')
        source = require('./assets/infominds_tree_light.png');
      else
        source = require('./assets/infominds_tree_dark.png');
    }

    let len = props.isOffline && Layout.isSmallDevice ? 133 : 200

    return (<Image
      style={[{ width: len, height: len, margin: 50, marginTop: 5 }, props?.iconStyle]}
      source={source} />)
  }


  const ShowInfo = ({ text }: any) => {
    if (text !== '' && notificationType != '') {
      var borderCol = Colors.info;
      switch (notificationType) {
        case 'error': borderCol = Colors.error; break;
        case 'warn': borderCol = '#EEB140'; break;
        default: break;
      }

      return (
        <View style={[
          styles.errorView,
          { borderColor: borderCol }]}>
          <Text style={{
            color: borderCol,
            maxWidth: '90%'
          }}>{text}</Text>
        </View>
      )
    }
    else return (<View />)
  }

  if (isLoading)
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center', height: Layout.window.height }}>
        <LoadingIndicator isVisible={true} />
      </View>
    )

  return (
    <View style={[{ backgroundColor: Colors[colorScheme].background }, props.style]}>
      <ScreenIcon></ScreenIcon>

      <ShowInfo text={notificationText}></ShowInfo>

      <LicenseBox
        placeholder={language.LICENSE}
        style={[styles.input, {
          color: theme.text,
          backgroundColor: theme.inputBackground,
          borderColor: licenseWarn ? '#EEB140' : (licenseError ? Colors.error : theme.inputBorder)
        }]}
        inputStyle={{
          color: theme.text,
        }}
        onChangeText={setLicense}
        value={license}
        onPress={toggleScanner}
      />

      {props.isOffline ?
        <Input
          placeholder="Server URL..."
          style={[styles.input, {
            color: theme.text,
            backgroundColor: theme.inputBackground,
            borderColor: urlError ? Colors.error : theme.inputBorder
          }]}
          onChangeText={text => {
            setUrl(text);
            AsyncStorage.setItem('url', text);
          }}
          value={url}></Input>
        :
        <></>
      }

      <Input
        placeholder={language.USERNAME}
        style={[styles.input, {
          color: theme.text,
          backgroundColor: theme.inputBackground,
          borderColor: usernameError ? Colors.error : theme.inputBorder
        }]}
        onChangeText={setUsername}
        value={username}></Input>
      <PasswordInput
        placeholder={language.PASSWORD}
        style={[styles.input, {
          color: theme.text,
          backgroundColor: theme.inputBackground,
          borderColor: theme.inputBorder,
          marginBottom: 0
        }]}
        onChangeText={(text) => setPassword(text)}
        value={password} />


      {Platform.OS === 'web' ? <View style={{ height: 20 }}></View> : <></>}

      <Button
        style={styles.loginButton}
        title="Login"
        onPress={onLoginButtonPress} />


      {(username || password || license) ? <></> : <View style={props.style}>
        {/* <Text style={{ color: theme.text }}>- or - </Text> */}

        <Button
          style={styles.loginButton}
          title={'Demo'}
          onPress={onDemoLogin} />
      </View>}


      <QRScannerPopUp
        isScannerVisible={isScannerVisible}
        toggleScanner={toggleScanner}
        setLicense={setLicense}
        setScannerVisible={setScannerVisible} />

    </View >
  );
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 10,
    width: '100%',
  },
  loginButton: {
    width: '100%',
    marginTop: 20
  },
  loadingContainer: {
    flex: 1,
    alignContent: 'center',
    textAlignVertical: 'center',
    justifyContent: 'center'
  },
  errorView: {
    paddingVertical: 10,
    paddingHorizontal: 5,
    width: '100%',
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    borderColor: Colors.error,
    borderWidth: 1,
    borderRadius: 6,
    marginBottom: 20
  }
});

export default Login;

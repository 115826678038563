import * as React from 'react'

import { View } from 'react-native';
import { TouchableOpacity } from 'react-native';
import { Colors } from '../constants/Colors';
import ThemeContext from '../contexts/ThemeContext';
import Icon from './Icon';
import Text from './Text';

export default function BackButton(props: { onPress: any, text: string, textVisible?: boolean }) {
  const colorScheme = React.useContext(ThemeContext);
  const theme = Colors[colorScheme];

  return (
    <TouchableOpacity
      onPress={props.onPress}>
      <View style={{
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        width: '30%',
        padding: 5,
        marginBottom: 10,
      }}>

        <Icon
          name={'arrow-left'}
          size={28}
          color={theme.text}></Icon>
        {props.textVisible ?
          <Text
            style={{ fontSize: 20, marginLeft: 10 }}
            onPress={props.onPress}>
            {props.text}
          </Text> : <></>}

      </View>
    </TouchableOpacity>
  )

}

import { InfomindsColors } from "../constants/Colors";

const ERRORCOLOR = InfomindsColors.red;

const ImValidators = {
  requiredInput(statevar: any, showErrors = true) {
    if (showErrors && !statevar) {
      return { borderColor: ERRORCOLOR };
    }
    return {};
  },

  requiredText(statevar: any, showErrors = true) {
    if (showErrors && !statevar) {
      return { color: ERRORCOLOR };
    }
    return {};
  },

  requiredAmount(statevar: number, showErrors = true) {
    if (showErrors && !statevar) {
      if (statevar <= 0)
        return { color: ERRORCOLOR };
    }
    return {};
  }
  ,
  validateDate(statevar: Date | undefined, onlyHistory: boolean, maxHistoryDays: number, showErrors = true) {
    var myPastDate = new Date();
    myPastDate.setDate(myPastDate.getDate() - maxHistoryDays);

    if (statevar === undefined)
      return { color: ERRORCOLOR };

    if (showErrors) {
      if (onlyHistory) {
        if (statevar >= new Date())
          return { color: ERRORCOLOR };
        if (statevar < myPastDate)
          return { color: ERRORCOLOR };
      }
    }
    if (myPastDate === undefined)
      return { color: ERRORCOLOR };
    return {};
  }
}

export default ImValidators;

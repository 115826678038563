import React, { useContext } from 'react';
import { Colors } from '../constants/Colors';
import ThemeContext from '../contexts/ThemeContext';
import { Picker as DefaultPicker } from '@react-native-picker/picker'

export interface PickerItem {
  value: any;
  label: string;
}

type Props = {
  items: PickerItem[];
  value?: any;
};

//@ts-ignore
export type IMPickerWebProps = Props & DefaultPicker['props'];

export default function Picker(props: IMPickerWebProps) {

  const colorScheme = useContext(ThemeContext);
  const theme = Colors[colorScheme];
  const { style, items, ...otherProps } = props;

  return (
    <DefaultPicker
      style={[{ backgroundColor: theme.inputBackground, color: theme.text, borderWidth: 0 }, style]}
      {...otherProps}>
      {
        items.map((item: PickerItem) => {
          return (<DefaultPicker.Item label={item.label} value={item.value} />)
        })
      }
    </DefaultPicker>
  )
}

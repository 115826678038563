import type { DeviceData } from "./Types";
import base64 from 'react-native-base64';
import LicenseGlobals from "./LicenseGlobals";
import AsyncStorage from '@react-native-community/async-storage';

const url = "https://rxios.infominds.eu/im.api.ios/IMAPIIOS"

const ApiLic = {
  getMandants() {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", LicenseGlobals.token);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders
      };

      fetch(LicenseGlobals.baseUrl + "/mandants", requestOptions)
        .then(response => response.json())
        .then(result => resolve(result.data))
        .catch(error => {
          console.error('error', error);
          reject(error);
        });
    })
  },

  getUserInfo() {
    return new Promise((resolve, reject) => {


      AsyncStorage.getItem('username')
        .then((username) => {
          if (username != null) {
            AsyncStorage.getItem('password')
              .then((password) => {
                //console.log(username, password);

                var myHeaders = new Headers();
                myHeaders.append("Authorization", LicenseGlobals.token);
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                var urlencoded = new URLSearchParams();
                urlencoded.append("grant_type", "password");
                urlencoded.append("client_id", "IM.Api");
                urlencoded.append("client_secret", "21B5F798-BE55-42BC-8AA8-0025B903DC3B");
                urlencoded.append("scope", "api1 openid IdentityServerApi");
                urlencoded.append("username", username);

                if (password) {
                  urlencoded.append("password", base64.encode(password));
                }

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: urlencoded
                };

                fetch(LicenseGlobals.baseUrl + "/connect/userinfo", requestOptions)
                  .then(response => response.json())
                  .then(result => {
                    // console.debug('Info: ', result);
                    resolve(result)
                  })
                  .catch(error => {
                    console.error('error', error);
                    reject(error);
                  });


              }).catch((error) => console.error(error));;
          }
        }).catch((error) => console.error(error));



    })
  },

  postRegisterDevice(licenseId: string, language: string, deviceData: DeviceData, projectCode?: string, modulCode?: string,): Promise<any> {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(deviceData)
      };

      const searchParams = new URLSearchParams({
        Licenceid: licenseId,
        Language: language
      });
      if (projectCode) searchParams.append('ProjectCode', projectCode);
      if (modulCode) searchParams.append('ProjectCode', modulCode);

      console.debug('POST', requestOptions, searchParams);

      fetch(url + "/RegisterDeviceLicence?" + searchParams, requestOptions)
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => reject(error));
    })
  },

  getCheckLicense(licenseId: string, language: string, deviceData: DeviceData): Promise<any> {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders
      };

      const searchParams = new URLSearchParams({
        Licenceid: licenseId,
        Language: language,
        'Devicedata.deviceId': deviceData.DeviceId,
        'Devicedata.user': deviceData.User,
        'Devicedata.deviceName': deviceData.DeviceName,
        'Devicedata.appVersion': deviceData.AppVersion,
        'Devicedata.osVersion': deviceData.OsVersion,
        'Devicedata.info': deviceData.Info
      });

      console.debug('GET', requestOptions, searchParams);

      fetch(url + "/CheckDeviceLicence?" + searchParams, requestOptions)
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => reject(error));
    })
  },

  login(username: string, password: string) {
    return new Promise<string>((resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      myHeaders.append("Access-Control-Allow-Origin", "*");

      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "password");
      urlencoded.append("client_secret", "21B5F798-BE55-42BC-8AA8-0025B903DC3B");
      urlencoded.append("client_id", "IM.Api");
      urlencoded.append("scope", "api1 openid IdentityServerApi");
      urlencoded.append("username", username);

      if (password) {
        urlencoded.append("password", base64.encode(password));
      }

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded.toString()
      };

      // console.debug('Login: ' + LicenseGlobals.baseUrl);
      // console.debug(urlencoded.toString());

      setTimeout(() => {
        reject(new Error('Server offline or not reachable'))
      }, LicenseGlobals.requestTimeOut)

      fetch(LicenseGlobals.baseUrl + "/connect/token", requestOptions)
        .then(response => {
          if (response.status === 200)
            return response.json()
          throw new Error('Username or password incorrect');
        })
        .then(result => {
          console.debug(result.error);
          console.debug(result.error_description);
          if (result.error) {
            reject(result.error_description)
          } else {
            let token = result.token_type + ' ' + result.access_token;
            //console.debug(token, '\n\n\n');

            resolve(token);
          }
        })
        .catch(error => {
          console.error('error', error);
          reject(error);
        });
    })
  }
}

export default ApiLic;

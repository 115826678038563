import React from 'react';
import { ActivityIndicator, View, StyleSheet } from 'react-native';
import { Colors } from '../constants/Colors';

type Props = {
    isVisible: boolean
};

export type LoadingIndicatorProps = Props & ActivityIndicator['props'];

export default function LoadingIndicator(props: LoadingIndicatorProps) {
    const { style, ...otherProps } = props;

    if (props.isVisible)
        return (
            <View style={[styles.loadingContainer]}>
                <ActivityIndicator size="large" color={Colors.tint} {...otherProps} />
            </View>
        )
    else
        return (<View />);
}

const styles = StyleSheet.create({
    loadingContainer: {
        flex: 1,
        alignContent: 'center',
        textAlignVertical: 'center',
        justifyContent: 'center'
    },
});
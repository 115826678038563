export default {
  jsonifyErgoString(ergos: string) {
    // replace all the = with : except the #.SERIALNR=X
    ergos = ergos.replace(/=/g, '\":\"');
    ergos = ergos.replace(/ARTICLEID":"/g, 'ARTICLEID=');
    ergos = ergos.replace(/#.SERIALNR":"/g, '#.SERIALNR=');
    // Wrap the props in "" and delete the "" at the end
    ergos = ergos.replace(/  /g, '\", \"');
    ergos = ergos.replace(/, ""/g, '');
    // replace the brackets
    ergos = ergos.replace('[', '{');
    ergos = ergos.replace(']', '}');

    // console.log('Converted JSON: ' + ergos);

    return JSON.parse(ergos);
  },
  createGetRequestOptions(LicenseGlobals: any) {
    return {
      method: 'GET',
      headers: createHeader(LicenseGlobals)
    };
  },
  createDeleteRequestOptions(LicenseGlobals: any) {
    return {
      method: 'DELETE',
      headers: createHeader(LicenseGlobals)
    };
  },
  createPostRequestOptions(LicenseGlobals: any, data: any) {
    return {
      method: 'POST',
      headers: createHeader(LicenseGlobals),
      body: JSON.stringify(data)
    };
  },
  createPutRequestOptions(LicenseGlobals: any, data: any) {
    return {
      method: 'PUT',
      headers: createHeader(LicenseGlobals),
      body: JSON.stringify(data)
    };
  },
  createHeader(LicenseGlobals: any) {
    return createHeader(LicenseGlobals);
  }
}

function createHeader(LicenseGlobals: any) {
  var myHeaders = new Headers();

  myHeaders.append("Authorization", LicenseGlobals.token);

  if (LicenseGlobals.mandantId) {
    myHeaders.append("mandantID", LicenseGlobals.mandantId.toString());
  }

  myHeaders.append("Content-Type", "application/json");

  return myHeaders;
}

export function GET<T>(LicenseGlobals: any, url: string, resultToReturn: ((result: any) => T) | undefined = undefined) {
  return new Promise<T>((resolve, reject) => {
    console.debug(LicenseGlobals.baseUrl + url)
    fetch(LicenseGlobals.baseUrl + url, GETOptions(LicenseGlobals))
      .then(response => { return response.json() })
      .then(result => {
        if (resultToReturn !== undefined)
          resolve(resultToReturn(result))
        else
          resolve(result.data)
      })
      .catch(error => {
        console.error('error', error);
        reject(error);
      });
  })
}

function GETOptions(LicenseGlobals: any) {
  return {
    method: 'GET',
    headers: createHeader(LicenseGlobals)
  };
}


export function POST<T>(LicenseGlobals: any, url: string, body: any) {
  return new Promise<T>((resolve, reject) => {
    console.debug(LicenseGlobals.baseUrl + url)
    fetch(LicenseGlobals.baseUrl + url, POSTOptions(LicenseGlobals, body))
      .then(response => { return response.json() })
      .then(result => resolve(result.data))
      .catch(error => {
        console.error('error', error);
        reject(error);
      });
  })
}

function POSTOptions(LicenseGlobals: any, body: any) {
  return {
    method: 'POST',
    headers: createHeader(LicenseGlobals),
    body: JSON.stringify(body)
  };
}


export function PUT<T>(LicenseGlobals: any, url: string, body: any) {
  return new Promise<T>((resolve, reject) => {
    console.debug(LicenseGlobals.baseUrl + url)
    fetch(LicenseGlobals.baseUrl + url, PUTOptions(LicenseGlobals, body))
      .then(response => response.json())
      .then(result => resolve(result.data))
      .catch(error => {
        console.error('error', error);
        reject(error);
      });
  })
}

function PUTOptions(LicenseGlobals: any, data: any) {
  return {
    method: 'PUT',
    headers: createHeader(LicenseGlobals),
    body: JSON.stringify(data)
  };
}

import React, { useContext, useState } from 'react';
import { Dimensions, ImageBackground, Platform, SafeAreaView, ScrollView, StyleSheet, View as DefaultView } from 'react-native';
import { Colors } from '../constants/Colors';
import ThemeContext from '../contexts/ThemeContext';
import View from './View';

type Props = {
  children: any;
  backgroundImage?: 'ellipse1' | 'ellipse2' | 'ellipse3' | 'ellipse4' | 'ellipse5' | 'ellipse6' | 'ellipse7' | 'ellipse8' | 'ellipse10' | 'ellipseFull' | 'none';
  scrollable?: boolean;
};

export type ScreenProps = Props & DefaultView['props'];

export default function Screen(props: ScreenProps) {
  const colorScheme = useContext(ThemeContext);

  const { style, ...otherProps } = props;

  const ContentWithBackgroundImage = () => {
    let resultImage = require('../assets/img/ellipse1.png');
    if (background === 'ellipse1')
      resultImage = require('../assets/img/ellipse1.png');
    else if (background === 'ellipse2')
      resultImage = require('../assets/img/ellipse2.png');
    else if (background === 'ellipse3')
      resultImage = require('../assets/img/ellipse3.png');
    else if (background === 'ellipse4')
      resultImage = require('../assets/img/ellipse4.png');
    else if (background === 'ellipse5')
      resultImage = require('../assets/img/ellipse5.png');
    if (background === 'ellipse6')
      resultImage = require('../assets/img/ellipse6.png');
    if (background === 'ellipse7')
      resultImage = require('../assets/img/ellipse7.png');
    if (background === 'ellipse8')
      resultImage = require('../assets/img/ellipse8.png');
    if (background === 'ellipse10')
      resultImage = require('../assets/img/ellipse10.png');
    if (background === 'ellipseFull')
      resultImage = require('../assets/img/ellipseFull.png');
    else if (background === 'none')
      resultImage = undefined;

    return resultImage
  };

  const [background] = useState(props.backgroundImage);
  const [backgroundImage] = useState(ContentWithBackgroundImage());

  return (

    <DefaultView
      style={[
        styles.container,
        {
          backgroundColor: Colors[colorScheme].background,
          height: (Platform.OS === 'web') ? (Dimensions.get('window').height - 100) : '100%'
        },
        style]}
      {...otherProps}>

      {props.scrollable ?
        <ScrollView style={{ backgroundColor: Colors[colorScheme].background }} >
          {
            background ?
              (
                <ImageBackground source={backgroundImage}
                  imageStyle={{
                    opacity: colorScheme === 'dark' ? 0.8 : 1
                  }}
                  style={{
                    flex: 1,
                    height: '100%',
                    opacity: colorScheme === 'dark' ? 0.1 : 1,
                    backgroundColor: Colors[colorScheme].background
                  }}>
                  <SafeAreaView style={{ flex: 1 }}>
                    <View style={[styles.content, style]}>
                      {props.children}
                    </View>
                  </SafeAreaView>
                </ImageBackground>)
              :
              <SafeAreaView style={{ flex: 1 }}>
                <View style={[styles.content, style]}>
                  {props.children}
                </View>
              </SafeAreaView>
          }
        </ScrollView>
        :
        <View style={{
          backgroundColor: Colors[colorScheme].background,
          flex: 1
        }} >
          {
            background ?
              (
                <ImageBackground source={backgroundImage}
                  imageStyle={{
                    opacity: colorScheme === 'dark' ? 0.8 : 1
                  }}
                  style={{
                    flex: 1,
                    height: '100%',
                    backgroundColor: Colors[colorScheme].background
                  }}>
                  <SafeAreaView style={{ flex: 1 }}>
                    <View style={[styles.content, style]}>
                      {props.children}
                    </View>
                  </SafeAreaView>
                </ImageBackground>)
              :
              <SafeAreaView style={{ flex: 1 }}>
                <View style={[styles.content, style]}>
                  {props.children}
                </View>
              </SafeAreaView>
          }
        </View>
      }

    </DefaultView >

  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    flex: 1
  },
  content: {
    height: '100%',
    padding: 10,
    flex: 1,
  }
})

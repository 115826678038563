export default {
  baseUrl: '',
  token: '',
  mandantId: -1,
  requestTimeOut: 5000, // ms
  license: '',
  username: '',
  erp: '',
  code: '',
  employeeID: -1
}

import ApiLic from "./ApiLic";
import getSystemLanguage from "./utils/LanguageUtils";
import type { DeviceData } from "./Types";
import DeviceInfo from 'react-native-device-info';
import AsyncStorage from "@react-native-community/async-storage";
import { Platform } from "react-native";
import LicenseGlobals from "./LicenseGlobals";

type GetDeviceData = {
  language: string,
  deviceData: DeviceData
}

function getDeviceData(username: string) {
  return new Promise<GetDeviceData>((resolve) => {
    getSystemLanguage().then((langIdentifyer: string) => {
      DeviceInfo.getDeviceName().then((devicename: string) => {
        const deviceData: DeviceData = {
          DeviceId: DeviceInfo.getUniqueId(),
          DeviceName: devicename.substring(0, 29),
          AppVersion: DeviceInfo.getVersion(),
          OsVersion: Platform.OS + (Platform.OS !== 'web' ? (' ' + (Platform.OS === 'android' ? DeviceInfo.getSystemVersion().toString() : Platform.Version.toString())) : ''),
          User: username,
          Info: ''
        };
        resolve({ language: langIdentifyer, deviceData: deviceData })
      });
    });
  });
}

function doLogin(username: string, password: string, customLoginFunction?: (username: string, password: string) => Promise<any>) {
  return new Promise((resolve, reject) => {
    const loginFun = (customLoginFunction ? customLoginFunction : ApiLic.login);

    loginFun(username, password)
      .then((token: string) => {
        LicenseGlobals.token = token;
        resolve(token)
      })
      .catch(err => reject(err));
  });
}

const LicenseUtil = {
  login: (username: string, password: string, customLoginFunction?: (username: string, password: string) => Promise<any>) => {
    return doLogin(username, password, customLoginFunction);
  },
  registerLicense: (license: string, username: string, password: string, projectCode?: string, modulCode?: string, customLoginFunction?: (username: string, password: string) => Promise<any>) => {
    return new Promise(async (resolve, reject) => {
      let data: GetDeviceData = await getDeviceData(username);

      if (license.includes('|'))
        license = license.split('|')[0];

      ApiLic.postRegisterDevice(license, data.language, data.deviceData, projectCode, modulCode,).then(result => {
        console.log('POST result: ', result);

        AsyncStorage.setItem('licenseKey', license);
        LicenseGlobals.license = license;

        if (result?.Data?.ActivationStatus === 'active') {
          if (result?.Data?.WebserviceUrl) {
            LicenseGlobals.baseUrl = result?.Data?.WebserviceUrl;
            AsyncStorage.setItem('url', result?.Data?.WebserviceUrl);
            LicenseGlobals.erp = result?.Data?.Erp;
            AsyncStorage.setItem('erp', result?.Data?.Erp);

            // set firmId only if there was no firmID selected manually in the app
            AsyncStorage.getItem('code').then(code => {
              if (code != null && code != undefined)
                LicenseGlobals.code = code
              else {
                LicenseGlobals.code = result?.Data?.Code;
                if (result?.Data?.Code !== undefined)
                  AsyncStorage.setItem('code', result?.Data?.Code);
              }
            })
            // console.debug(LicenseGlobals.baseUrl);

            doLogin(username, password, customLoginFunction)
              .then(() => resolve('login'))
              .catch(error => reject(error));
          } else {
            resolve('no url');
          }
        } else if (result?.Data?.ActivationStatus === 'inactive') {
          reject('inactive')
        } else {
          reject(result?.Errors?.Messages[0].Message)
        }
      }).catch((error) => {
        console.error(error);
        reject('Login error')
      });
    });
  }
}

export default LicenseUtil;

import React from 'react';
import { FlatList } from 'react-native';

export interface ListRenderItemInfo<ItemT> {
    item: ItemT;

    index: number;

    separators: {
        highlight: () => void;
        unhighlight: () => void;
        updateProps: (select: 'leading' | 'trailing', newProps: any) => void;
    };
}

export type ListRenderItem<ItemT> = (info: ListRenderItemInfo<ItemT>) => React.ReactElement | null;

type Props<ItemT> = {
    data?: (any[] & readonly any[]) | undefined,
    renderItem: ListRenderItem<ItemT> | null | undefined,
    keyExtractor?: (item: ItemT, index: number) => string
}

export type ListProps<ItemT> = Props<ItemT> & FlatList['props'];

export default function List<ItemT>(props: ListProps<ItemT>) {
    const { ...otherProps } = props;

    return (
        <FlatList
            {...otherProps}>
        </FlatList>
    )
}
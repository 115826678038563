import * as React from 'react'
import { TouchableOpacity } from 'react-native';
import { Colors } from '../constants/Colors';
import ThemeContext from '../contexts/ThemeContext';
import Icon from './Icon';

export default function ModalCloseButton(props: { onPress: any }) {
  const colorScheme = React.useContext(ThemeContext);
  const theme = Colors[colorScheme];

  return (
    <TouchableOpacity onPress={() => props.onPress()}>
      <Icon name='x' size={28} color={theme.text} style={{ alignSelf: 'flex-end' }}></Icon>
    </TouchableOpacity>
  )

}

import React, { useContext } from 'react';
import DefaultIcon from 'react-native-vector-icons/dist/Feather';
import FontAwesomeIcon from 'react-native-vector-icons/dist/FontAwesome5';
import { Colors } from '../constants/Colors';
import ThemeContext from '../contexts/ThemeContext';

type Props = {
    family?: 'Feather' | 'FontAwesome5'
};

export type IconProps = Props & typeof DefaultIcon['props'];

const Icon = (props: IconProps) => {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];

    const { color, ...otherProps } = props;

    if (props.family === 'FontAwesome5')
        return (<FontAwesomeIcon color={color ? color : theme.text} {...otherProps}></FontAwesomeIcon>)

    return (<DefaultIcon color={color ? color : theme.text} {...otherProps}></DefaultIcon>)

}

export default Icon;
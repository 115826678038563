import React from 'react';
import type { Switch as DefaultSwitch } from 'react-native';
import DarkModeToggle from "react-dark-mode-toggle";

type Props = {
    isEnabled?: boolean
    onValueChange: () => any
    value?: boolean
};

export type SwitchDarkModeProps = Props & DefaultSwitch['props'];

export default function SwitchDarkMode(props: SwitchDarkModeProps) {
    const { style, ...SwitchProps } = props;

    return (
        <DarkModeToggle
            onChange={() => props.onValueChange()}
            checked={props.value}
            size={60}
            {...SwitchProps}
        />
    )
} 
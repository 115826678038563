import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, TextInput as DefaultTextInput, View } from 'react-native';
import { Colors } from '../constants/Colors';
import Layout from '../constants/Layout'
import ThemeContext from '../contexts/ThemeContext';
import Text from './Text';

type Props = {
  maxLength: number
};

export type TextInputProps = Props & DefaultTextInput['props'];

export default function InputMaxLength(props: TextInputProps) {
  const colorScheme = useContext(ThemeContext);
  const theme = Colors[colorScheme];
  const { style, value, ...otherProps } = props;

  const [text, setText] = useState<string | undefined>('')

  useEffect(() => {
    if (props.value && props.value?.length >= props.maxLength)
      setText(value?.substr(0, props.maxLength))
    else
      setText(value)
  }, [value])

  return (
    <View style={[styles.searchSection, Layout.defaultComponentsStyles,
    {
      backgroundColor: theme.inputBackground,
      borderColor: theme.inputBorder
    }, style]}>
      <DefaultTextInput
        style={[
          {
            flex: 1,
            color: theme.text,
            paddingStart: 12,
            paddingVertical: 12
          },
          style]}
        placeholderTextColor={theme.textPlaceholder}
        value={text}
        {...otherProps}
      >
      </DefaultTextInput>
      <Text style={{ color: theme.textPlaceholder }}>{props.value ? props.value.length : 0}/{props.maxLength}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderRadius: 8,

  },
  searchSection: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 8,
    // padding: 12,
    paddingRight: 5
  },
  searchIcon: {
    padding: 5,
  },
});

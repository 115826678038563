import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { Colors } from '../constants/Colors';
import ThemeContext from '../contexts/ThemeContext';
import type { TextInputProps } from './Input'
import View from './View';
import Layout from '../constants/Layout';
import { TextInput } from 'react-native';
import Icon from './Icon';

export default function SearchBox(props: TextInputProps) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];
    const { style, ...otherProps } = props;

    return (
        <View style={[styles.searchSection, Layout.defaultComponentsStyles,
        {
            backgroundColor: theme.inputBackground,
            borderColor: theme.inputBorder
        }, style]}>
            <TextInput
                style={[styles.input, { color: theme.text }]}
                placeholder="Search..."
                // onChangeText={(searchString) => { this.setState({ searchString }) }}
                underlineColorAndroid="transparent"
                placeholderTextColor={theme.textPlaceholder}
                {...otherProps}
            />
            {(props.value && props.value !== '') ?
                <Icon style={styles.searchIcon} name="x" size={20} color={theme.textDetail}
                    onPress={() => { props.onChangeText && props.onChangeText('') }} />
                :
                <Icon style={styles.searchIcon} name="search" size={20} color={theme.textDetail} />
            }
        </View>
    )
}

const styles = StyleSheet.create({
    input: {
        flex: 1,
        padding: 12
    },
    searchSection: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 8,
        paddingRight: 5
    },
    searchIcon: {
        padding: 5,
    },

});

import React from 'react';
import { View as DefaultViewProps } from 'react-native';

type Props = {
};

export type ViewProps = Props & DefaultViewProps['props'];

export default function View(props: ViewProps) {
    const { style, ...otherProps } = props;

    return (
        <DefaultViewProps
            style={[style]}
            {...otherProps}
        />
    )
}
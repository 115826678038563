import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Colors } from "../constants/Colors";
import Layout from "../constants/Layout";
import ThemeContext from "../contexts/ThemeContext";
import CardBasic from "./CardBasic";
import ContentPopupModal from "./ContentPopupModal";
import Icon from "./Icon";
import List from "./List";
import Text from "./Text";
import View from "./View";

export default function PickerModal(props: {
  data: any,
  renderSelected: (item: any) => string
  renderItemContent: ({ item, index }: any) => JSX.Element,
  onDelete?: () => void,
  onSelect?: (item: any) => void,
  placeholder?: string,
  editable?: boolean
  value?: any,
  style?: any
}) {
  const colorScheme = React.useContext(ThemeContext);
  const theme = Colors[colorScheme];

  const [selected, setSelected] = useState();

  const [isModalBottomVisible, setModalBottomVisible] = useState(false);
  const toggleModalBottom = () => setModalBottomVisible(!isModalBottomVisible);

  useEffect(() => {
    setSelected(props.value)
  }, [props.value, selected]);

  return (
    <View>
      <TouchableOpacity onPress={() => { if (props.editable !== false) toggleModalBottom() }}
        style={[
          styles.input,
          props.style,
          Layout.defaultComponentsStyles]}>

        <View style={{ flex: 1, alignItems: 'flex-end' }}>
          <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
            {selected ?
              <Text style={{ margin: 5, marginRight: 10 }}>{props.renderSelected(selected)}</Text>
              :
              <Text style={{ color: theme.textPlaceholder, margin: 0 }}>{props.placeholder ?? 'select'}</Text>
            }
            <Icon family='FontAwesome5' name='sort-down' size={20} color={Colors.icon} style={{ padding: 0, margin: 5, marginTop: 0 }} />
          </View>
        </View>

      </TouchableOpacity>

      <ContentPopupModal
        isVisible={isModalBottomVisible}
        close={toggleModalBottom}
        style={{
          height: 'auto',
          minHeight: '30%',
          maxHeight: '90%',
          width: Layout.isSmallDevice ? '96%' : '70%',
          marginLeft: Layout.isSmallDevice ? '2%' : '15%',
        }}
        content={() =>
          <List
            data={props.data}
            style={{ margin: 8 }}
            renderItem={({ item, index }: any) => (
              <TouchableOpacity onPress={() => { setSelected(item); toggleModalBottom(); props.onSelect?.(item) }}>
                <CardBasic>
                  {props.renderItemContent({ item, index })}
                </CardBasic>
              </TouchableOpacity>
            )}
          />
        }
      />
    </View >
  );
}

const styles = StyleSheet.create({
  button: {
    padding: 12,
    borderWidth: 1,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
    padding: 0,
    alignContent: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
});

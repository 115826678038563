import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { Colors } from '../constants/Colors';
import ThemeContext from '../contexts/ThemeContext';
import View from './View';
import Layout from '../constants/Layout';
import { TextInput } from 'react-native';


export default function LicenseBox(props: any) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];
    const { style, inputStyle, ...otherProps } = props;

    return (
        <View style={[styles.searchSection, Layout.defaultComponentsStyles,
        {
            backgroundColor: theme.inputBackground,
            borderColor: theme.inputBorder
        }, style]}>
            <TextInput
                style={[styles.input, { color: theme.text }, inputStyle]}
                placeholder="License"
                underlineColorAndroid="transparent"
                placeholderTextColor={theme.textPlaceholder}
                {...otherProps}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    input: {
        flex: 1,
        padding: 12
    },
    searchSection: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 8,
        paddingRight: 5
    },
    searchIcon: {
        padding: 5,
    },

});
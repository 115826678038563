import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default {
    window: {
        width,
        height,
    },
    isSmallDevice: width < 475,
    isLargeDevice: width >= 475,
    defaultComponentsStyles: {
        margin: 5
    }
};
import React, { useContext } from 'react';
import { Switch as DefaultSwitch } from 'react-native';
import { Colors } from '../constants/Colors';
import ThemeContext from '../contexts/ThemeContext';

type Props = {
    isEnabled?: boolean
};

export type SwitchProps = Props & DefaultSwitch['props'];

export default function Switch(props: SwitchProps) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];
    const { style, ...SwitchProps } = props;

    return (
        <DefaultSwitch
            trackColor={{ false: "#767577", true: theme.inputBackground }}
            thumbColor={props.isEnabled ? Colors.tint : "#f4f3f4"}
            ios_backgroundColor="#3e3e3e"
            {...SwitchProps}
        />
    )
}
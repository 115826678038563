import React from 'react';
import TreeView from 'react-native-final-tree-view'

export type RenderNode = {
    node: any,
    level: number,
    isExpanded: boolean,
    hasChildrenNodes: boolean
}

type Props = {
    data?: any[],
    renderNode?: any
}

export type TreeProps = Props;

// const getIndicator = (isExpanded: boolean, hasChildrenNodes: boolean) => {
//     if (!hasChildrenNodes) {
//         return '*';
//     } else if (isExpanded) {
//         return '-';
//     } else {
//         return '+';
//     }
// };

export default function Tree(props: TreeProps) {
    const { ...otherProps } = props;

    return (

        // @ts-ignore
        < TreeView
            data={props.data}
            // @ts-ignore
            getCollapsedNodeHeight={() => {
                // default size when node is closed
            }}
            renderNode={props.renderNode}
            {...otherProps}
        />
    )
}
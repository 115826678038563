import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { Colors } from '../constants/Colors';
import ThemeContext from '../contexts/ThemeContext';
import Input, { TextInputProps } from './Input'


export default function Note(props: TextInputProps) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];
    const { style, ...otherProps } = props;

    return (
        <Input
            style={[
                styles.input,
                style]}
            placeholderTextColor={theme.textPlaceholder}
            multiline={true}
            {...otherProps}
        />
    )
}

const styles = StyleSheet.create({
    input: {
        height: 100,
        textAlignVertical: 'top'
    }
});
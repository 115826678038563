import React from 'react';
import { Button, InfomindsColors } from '@infominds/react-native-components';
import AsyncStorage from '@react-native-community/async-storage';
import LicenseGlobals from '.';

export default function LogoutButton(props: { logoutNavigation: any }) {
  function logout() {
    LicenseGlobals.license = '';
    LicenseGlobals.username = '';
    LicenseGlobals.erp = '';
    AsyncStorage.multiRemove(['licenseKey', 'username', 'password', 'offline'])
      .then(() => props.logoutNavigation())
      .catch(error => console.error(error));
  }

  return (
    <Button
      style={{ backgroundColor: InfomindsColors.red }}
      title="Logout"
      onPress={logout}
    />
  )
}

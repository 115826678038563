import React from 'react';
import { StyleSheet, TouchableOpacity as DefaultTouchableOpacity } from 'react-native';
import { Colors } from '../constants/Colors';
import Text from './Text'
import Layout from '../constants/Layout'

export enum ButtonType {
  primary,
  danger,
  info,
  neutral
}

type Props = {
  title?: string;
  type?: ButtonType;
  disabled?: boolean;
};

export type TouchableOpacityProps = Props & DefaultTouchableOpacity['props'];

export default function Button(props: TouchableOpacityProps) {
  const { style, ...otherProps } = props;

  let typeStyling = {}

  if (props.type) {

    switch (props.type as ButtonType) {
      case ButtonType.primary:
        typeStyling = { backgroundColor: Colors.tint }
        break;
      case ButtonType.danger:
        typeStyling = { backgroundColor: Colors.red }
        break;
      case ButtonType.info:
        typeStyling = { backgroundColor: Colors.yellow }
        break;
      case ButtonType.neutral:
        typeStyling = { backgroundColor: Colors.neutral }
        break;
      default:
        typeStyling = { backgroundColor: Colors.tint }
        break;
    }
  }

  return (
    <DefaultTouchableOpacity
      style={[
        Layout.defaultComponentsStyles,
        styles.button,
        style,
        typeStyling, {
          opacity: (props.disabled ? 0.5 : 1)
        },
      ]}
      activeOpacity={0.5}

      {...otherProps} >
      <Text style={styles.title}>
        {props.title}
      </Text>
    </DefaultTouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    padding: 12,
    borderRadius: 10,
    backgroundColor: Colors.tint
  },
  title: {
    alignSelf: 'center',
    color: '#fff'
  }
});

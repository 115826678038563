import * as React from 'react'
import { useState } from "react";
import List from './List';
import SearchBox from './SearchBox';
import View from './View';

SearchList.defaultProps = {
  searchBarOverlay: true
}

export default function SearchList(props: {
  data: any,
  renderItem: ({ item, index }: any) => JSX.Element,
  searchString: (item: any) => string,
  style?: any,
  scrollEnabled?: boolean,
  searchLabel?: string,
  searchBarOverlay?: boolean,
  headerStyle?: any,
  footer?: JSX.Element
}) {

  if (!props.searchLabel)
    props.searchLabel = 'search';

  const [searchtext, setSearchText] = useState<string>('');

  function searchFilterFunction(text: string) {
    if (text === '') {
      return props.data;
    } else {
      const newData = props.data.filter((item: any) => {
        const itemData = props.searchString(item).toUpperCase();
        const textData = text.toUpperCase();

        return itemData.includes(textData);
      });
      return newData;
    }
  }

  return (
    <List
      ListHeaderComponent={
        <View style={[props.searchBarOverlay ? { backgroundColor: 'transparent' } : {}, props.headerStyle]}>
          <SearchBox style={{ marginBottom: 10 }}
            placeholder={props?.searchLabel}
            onChangeText={text => setSearchText(text)}
          />
        </View>
      }
      scrollEnabled={props.scrollEnabled}
      stickyHeaderIndices={[0]}
      style={props.style}
      data={searchFilterFunction(searchtext)}
      renderItem={props.renderItem}
      ListFooterComponent={props?.footer}
      keyExtractor={(_item: any, index: any) => index.toString()}
    />
  )
}

import * as React from 'react';
import { Platform, SafeAreaView, ScrollView, StatusBar, StyleSheet, TouchableOpacity } from 'react-native';
import { View, Icon } from '@infominds/react-native-components';
import { Colors } from '@infominds/react-native-components';
import { useColorScheme } from '@infominds/react-native-components';
import Login from "./LoginView";
import { LicenseGlobals } from '.';
import { useState } from 'react';
import AsyncStorage from '@react-native-community/async-storage';


LoginScreen.defaultProps = {
  getMandants: true,
  getUserInfo: true
}

export default function LoginScreen(props: {
  navigation: any,
  isOffline: boolean,
  timeoutInMs: number,
  customLoginFunction?: (username: string, password: string) => Promise<any>,
  projectCode?: string,
  modulCode?: string,
  demoData?: { lic: string, username: string, password: string }
  iconSource?: any,
  iconStyle?: any,
  getMandants?: boolean,
  getUserInfo?: boolean,
  onSuccess?: () => void
}) {

  /* the !!props.isOffline converts undefined, '', false and null to false and true to true */
  const [offline, setOfflineSetter] = useState<boolean>(!!props.isOffline);
  function setOffline(value: boolean) {
    setOfflineSetter(value);
    AsyncStorage.setItem('offline', value.toString());
  }

  const colorScheme = useColorScheme();
  const theme = Colors[colorScheme];

  let onLoginClick = () => {
    // console.log(props.onSuccess);
    if (props.onSuccess)
      props.onSuccess();
    else
      props.navigation.replace('Root');
  };

  React.useEffect(() => {
    AsyncStorage.getItem('offline')
      .then(stored => {
        setOfflineSetter(stored === 'true');
      });

    AsyncStorage.getItem('erp').then(erp => {
      if (erp) LicenseGlobals.erp = erp;
    });

    if (props.timeoutInMs) {
      console.debug(`>>> Set timeout from ${LicenseGlobals.requestTimeOut} ms to ${props.timeoutInMs} ms`);
      LicenseGlobals.requestTimeOut = props.timeoutInMs;
    }
  }, [])

  // iOS always light because it is always darmode
  const StatusBarForgroundColor = Platform.OS === 'android' ? colorScheme === "dark" ? "light-content" : "dark-content" : "light-content"

  // needed to reset this value
  StatusBar.setTranslucent(false)

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors[colorScheme].background }}>
      <StatusBar
        translucent={false}
        backgroundColor={Colors[colorScheme].background}
        // barStyle={colorScheme === "dark" ? "light-content" : "dark-content"}
        barStyle={StatusBarForgroundColor}
      />
      <View style={{ flex: 1, justifyContent: 'space-between', backgroundColor: Colors[colorScheme].background }}>
        <ScrollView contentContainerStyle={{ flex: 1, justifyContent: 'center' }} keyboardShouldPersistTaps='handled'>
          <TouchableOpacity style={{ flex: 1, justifyContent: 'center', zIndex: 1, alignSelf: 'flex-end', padding: 20 }} onPress={() => setOffline(!offline)} >
            <Icon name={'sliders'} color={theme.inputBorder} size={25} />
          </TouchableOpacity>
          <View style={[styles.container, { marginTop: -65, left: 0, right: 0, backgroundColor: Colors[colorScheme].background }]} >
            <Login
              isOffline={offline}
              style={styles.login}
              projectCode={props.projectCode}
              modulCode={props.modulCode}
              customLoginFunction={props.customLoginFunction}
              onLoginClick={() => onLoginClick()}
              demoData={props.demoData}
              getMandants={props.getMandants}
              getUserInfo={props.getUserInfo}
              iconSource={props.iconSource}
              iconStyle={props?.iconStyle}></Login>
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center'
  },
  login: {
    width: '100%',
    maxWidth: 300,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'center'
  }
});

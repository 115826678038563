import React, { useContext } from 'react';
import { View } from 'react-native';
import { Colors } from '../constants/Colors';
import ThemeContext from '../contexts/ThemeContext';
import { Modal as DefaultModal } from 'react-native-web';

type Props = {
  isVisible?: boolean,
  close?: any,
  content: any,
  style?: any
};

export type ModalProps = Props;

export default function Modal(props: ModalProps) {
  const colorScheme = useContext(ThemeContext);
  const theme = Colors[colorScheme];

  const { style } = props;

  return (
    <DefaultModal
      transparent={true}
      animationType="fade"
      visible={props.isVisible}>
      <View style={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.8)'
      }}>
        <View style={[{
          padding: 30,
          borderWidth: 1,
          borderRadius: 10,
          borderColor: theme.text,
          backgroundColor: theme.backgroundModal
        }, style]}>
          {props.content}
        </View>
      </View >
    </DefaultModal >
  )
}
